import * as React from "react";

import { classnames } from "utils/classnames";

import Label, { ILabelProps } from "./Label";
import Input, { IInputProps } from "./Input";

type IFieldProps = Omit<ILabelProps, "error" | "children"> &
  Omit<IInputProps, "error" | "id"> & {
    title: string;
    error?: string;
    inputClassName?: string;
    required?: boolean;
    description?: React.ReactNode;
  };

const Field = ({
  title,
  onChange,
  error,
  className,
  inputClassName,
  required,
  description,
  ...rest
}: IFieldProps) => {
  const id = React.useId();

  return (
    <div className={className}>
      <Label htmlFor={id} error={!!error} className="block mb-2">
        {title}
        {required && <span className="text-coral ml-1">*</span>}
      </Label>
      <Input
        id={id}
        onChange={onChange}
        error={!!error}
        className={classnames("block w-full", inputClassName)}
        {...rest}
      />
      {error && <div className="block text-sm text-coral mt">{error}</div>}
      {description && <div className="block text-sm text-white/80 mt-2">{description}</div>}
    </div>
  );
};

export default Field;
