import * as React from "react";

import { classnames } from "utils/classnames";

const classes = {
  h1: "text-4xl lg:text-6xl font-black font-expanded mt-8 mb-10",
  h2: "text-xl lg:text-2xl font-bold font-condensed mt-10 mb-8",
  h3: "text-lg lg:text-xl font-bold font-condensed mt-8 mb-6",
  h4: "text-base lg:text-lg font-bold font-condensed mt-6 mb-4",
};

interface IHeadingProps {
  as: "h1" | "h2" | "h3" | "h4";
  className?: string;
  id?: string;
  children: React.ReactNode;
}

const Heading = ({ as: Component, className, children, ...rest }: IHeadingProps) => (
  <Component
    className={classnames(classes[Component], "first:mt-0 last:mb-0", className)}
    {...rest}
  >
    {children}
  </Component>
);

export default Heading;
