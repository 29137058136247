import * as React from "react";
import { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";

import { startRegistration, startAuthentication } from "@simplewebauthn/browser";
import {
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
  RegistrationResponseJSON,
  AuthenticationResponseJSON,
} from "@simplewebauthn/types";

import Field from "components/Form/Field";
import Heading from "components/Heading";
import Message from "components/Message";
import Button from "components/Button";
import Form from "components/Form/Form";

import { fetchJSON } from "utils/http";

interface LoginResp {
  waState: string;
  waSess: string;
  waOpts: {
    publicKey: PublicKeyCredentialCreationOptionsJSON | PublicKeyCredentialRequestOptionsJSON;
  };
  error: string;
}

const Login = ({ redirect }: { redirect?: string | null }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  const waStartRegistration = (
    optionsJSON: PublicKeyCredentialCreationOptionsJSON,
    sess: string,
  ) => {
    startRegistration({ optionsJSON })
      .then(resp => waFinish(resp, sess, "register"))
      .catch(err => {
        if (err.name === "InvalidStateError") {
          setError("Authenticator was probably already registered by user");
        } else if (err.name === "AbortError") {
          setLoading(false);
        } else {
          setError(err.message);
        }
      });
  };

  const waStartAuthentication = (
    optionsJSON: PublicKeyCredentialRequestOptionsJSON,
    sess: string,
  ) => {
    startAuthentication({ optionsJSON })
      .then(resp => waFinish(resp, sess, "auth"))
      .catch(err => {
        if (err.name === "AbortError") {
          setLoading(false);
        } else {
          setError(err.message);
        }
      });
  };

  const waFinish = (
    resp: AuthenticationResponseJSON | RegistrationResponseJSON,
    sess: string,
    state: string,
  ) => {
    fetchJSON<LoginResp>(`/login/complete?sess=${sess}&state=${state}`, {
      method: "POST",
      body: resp,
    })
      .then(({ data, status }) => {
        if (status === 200) {
          window.location.href = redirect && redirect.startsWith("/") ? redirect : "/";
        } else if (status === 422) {
          setError(data.error || "Invalid email or password");
        } else {
          setError("Something went wrong");
        }
      })
      .catch(() => {
        setError("Something went wrong");
      });
  };

  const onSubmit = () => {
    setLoading(true);
    setError(undefined);

    fetchJSON<LoginResp>("/login", { method: "POST", body: { email, password } })
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.waState === "register")
            waStartRegistration(
              data.waOpts.publicKey as PublicKeyCredentialCreationOptionsJSON,
              data.waSess,
            );
          else waStartAuthentication(data.waOpts.publicKey, data.waSess);
        } else if (status === 422) {
          setError(data.error || "Invalid email or password");
        } else {
          setError("Something went wrong");
        }
      })
      .catch(() => {
        setError("Something went wrong");
      });
  };

  return (
    <div className="absolute left-0 top-0 w-full min-h-full p-8 flex items-center justify-center">
      <Form onSubmit={onSubmit} loading={loading} className="w-full max-w-[500px] p-8 bg-white/5">
        <Heading as="h2">Login</Heading>

        {error && <Message type="error">{error}</Message>}

        <Field
          type="email"
          name="email"
          title="Email"
          placeholder="something@something.com"
          className="mb-4"
          onChange={setEmail}
        />
        <Field
          type="password"
          name="password"
          title="Password"
          className="mb-8"
          onChange={setPassword}
        />
        <Button type="submit" accented className="w-full">
          Login
        </Button>
      </Form>
    </div>
  );
};

const container = document.getElementById("app");

if (container) {
  const params = new URLSearchParams(window.location.search);
  const redirect = params.get("redirect");

  createRoot(container).render(<Login redirect={redirect} />);
}
