import { csrfToken } from "./csrfToken";

function prepareJSONInit(init: { method?: string; body?: any }): RequestInit {
  const update: RequestInit = {};

  if (init.method) {
    update.method = init.method.toUpperCase();
  }

  if (init.body) {
    update.body = JSON.stringify(init.body);
  }

  return Object.assign({}, init, update);
}

function getBaseJSONRequest(input: RequestInfo | URL): Request {
  const init: RequestInit = {
    credentials: "same-origin",
    headers: new Headers({
      "X-CSRF-Token": csrfToken(),
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    }),
  };

  return new Request(input, init);
}

function checkStatus(response: Response): Response {
  const { status } = response;

  if ((status >= 200 && status < 300) || status === 400 || status === 422) {
    return response;
  }

  const error: Error & { response: Response } = Object.assign({}, new Error(response.statusText), {
    response,
  });

  throw error;
}

function parseJson<T>(response: Response): Promise<Response & { data: T }> {
  return response
    .json()
    .then(json => Object.assign(response, { data: json as T }))
    .catch(error => {
      throw Object.assign(error, { response });
    });
}

export function fetchJSON<T = any>(
  input: RequestInfo | URL,
  init: { method?: string; body?: any } = {},
): Promise<Response & { data: T }> {
  const preparedInit = prepareJSONInit(init);

  return fetch(getBaseJSONRequest(input), preparedInit)
    .then(checkStatus)
    .then(r => parseJson<T>(r));
}
