import * as React from "react";

import { classnames } from "utils/classnames";

import { IconSpinner } from "components/Icons";

interface ILoadingProps {
  className?: string;
}

const Loading = ({ className }: ILoadingProps) => (
  <div
    className={classnames("z-10 bg-almost-black/90 flex items-center justify-center", className)}
  >
    <IconSpinner className="fill-white h-10" />
  </div>
);

export default Loading;
