import * as React from "react";

import Loading from "components/Loading";
import { classnames } from "utils/classnames";

interface IFormProps {
  children: React.ReactNode;
  loading?: boolean;
  onSubmit?: () => void;
  className?: string;
}

const Form = ({ children, loading = false, onSubmit, className }: IFormProps) => {
  const submit = (e: React.FormEvent) => {
    if (!onSubmit) return;

    e.preventDefault();
    onSubmit();
  };

  return (
    <form className={classnames("relative", className)} onSubmit={submit}>
      {loading && <Loading className="absolute left-0 top-0 w-full h-full" />}
      {children}
    </form>
  );
};

export default Form;
