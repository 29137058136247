import * as React from "react";

import { classnames } from "utils/classnames";

export interface IInputProps {
  type?: "text" | "password" | "email" | "number" | "select" | "textarea";
  id?: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  options?: { name: string; value: string }[];
  rows?: number;
  error?: boolean;
  className?: string;
  onChange?: (v: string) => void;
}

const Input = ({
  type = "text",
  options,
  rows,
  error = false,
  className,
  onChange,
  ...rest
}: IInputProps) => {
  const classes = classnames(
    "bg-white/5 border-2 p-3 outline-none hover:border-dodger-blue focus:border-dodger-blue",
    error ? "border-coral" : "border-white/20",
    type === "select" && "block w-full pr-10 appearance-none cursor-pointer",
  );

  if (type === "select") {
    return (
      <div
        className={classnames(
          "relative",
          "after:block after:content-['▼'] after:text-white after:pointer-events-none",
          "after:absolute after:right-4 after:top-1/2 after:-translate-y-1/2",
          className,
        )}
      >
        <select
          className={classes}
          onChange={onChange && (e => onChange(e.target.value))}
          {...rest}
        >
          {options &&
            options.map(o => (
              <option key={`opt-${o.value}`} value={o.value}>
                {o.name}
              </option>
            ))}
        </select>
      </div>
    );
  }

  if (type === "textarea") {
    return (
      <textarea
        rows={rows}
        onChange={onChange && (e => onChange(e.target.value))}
        className={classnames(classes, className)}
        {...rest}
      />
    );
  }

  return (
    <input
      type={type}
      onChange={onChange && (e => onChange(e.target.value))}
      className={classnames(classes, className)}
      {...rest}
    />
  );
};

export default Input;
