import * as React from "react";

import { classnames } from "utils/classnames";

export interface ILabelProps {
  htmlFor?: string;
  error?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const Label = ({ htmlFor, error, className, children }: ILabelProps) => (
  <label
    htmlFor={htmlFor}
    className={classnames("text-sm font-bold", error && "text-coral", className)}
  >
    {children}
  </label>
);

export default Label;
